.main-section {
  .full-width {
    .event-block {
      h1 {
        margin:0;
        padding:0;
        &:after {
          content:"";
          width:0;
          height:0;
        }
      }
      h1 + h2 {
        font-size:18px;
      }
    }
  }
}

.event-details {
  margin:0;
  padding:0;
  list-style:none;
  li {
    width:100%;
    display:block;
    position:relative;
    padding:.25rem 0;
    color:lighten($primary-font-color, 30%);
    i {
      margin-right:5px;
      font-size:18px;
    }
  }
  &.event-details-title {
    width:100%;
    display:block;
    overflow:auto;
    padding-bottom:1rem;
    li {
      width:auto;
      float:left;
      display:inline-block;
      margin-right:30px;
    }
  }
}
