::-moz-selection {
  background: $primary-color;
  color: white;
}

::selection {
  background: $primary-color;
  color: white;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100% !important;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: $primary-font-family;
  color: $primary-font-color;
  padding: 0;
  margin: 0;
  background: #f5f5f5 url('/images/bg.png') no-repeat;
  background-position: bottom right;
  background-attachment: fixed;
  z-index:0;
}

h1, h2, h3, h4, h5, h6 {
  color:$primary-color;
}

h1 {
  @include font_H1();
}
h2 {
  @include font_H2();
}

a {
  color:$secondary-color;
}
a, button {
  outline:0;
  &:focus {
    outline:0;
  }
}

#wrapper {
  box-shadow: 0 0 6px rgba(0,0,0,.3);
  position: relative;
  padding: 56px 0 0 0;
  background: #ffffff;
}

.text-block {
  p, ul, ol, li {
    @include font_P1();
    color:$primary-font-color;
  }
  img {
    max-width:100%;
    height:auto !important;
  }
}

.trigger {
  position:absolute;
  width:1px;
  height:1px;
  top:0;
  left:0;
}

.cols {
  display:flex;
  flex-wrap:wrap;
  position:relative;
  .col-half {
    width:100%;
    padding:1rem 2rem;
  }
}
.main-section {
  .full-width {
    width:100%;
    padding:2rem;
    position:relative;
    .text-block {
      position:relative;
    }
    h1 {
      @include font_H1();
      padding:0 0 1rem 0;
      margin:0 0 1rem 0;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width:50px;
        height:3px;
        background:$primary-color;
      }
    }
    h2 {
      @include font_H2();
      padding:0 0 1rem 0;
      margin:0 0 1rem 0;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width:50px;
        height:3px;
        background:$primary-color;
      }
    }
    a {
      color:$secondary-color;
    }
    p, ul, ol, li, table {
      @include font_P1();

    }
  }
}
.photos {
  padding:2rem 3rem;
  .masonry-container {
    .fancybox {
      display:block;
      position:relative;
      margin:0 0 2rem 0;
      text-decoration:none;
      cursor:pointer;
      &:before {
        content:"\f00e";
        font-family:"Font Awesome 5 Free";
        font-weight:700;
        font-size:0;
        color:white;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        transition:font-size ease-in-out .3s;
        z-index:2;
      }
      &:after {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        background:rgba(0,0,0,0);
        transition:background ease-in-out .3s;
        z-index:1;
      }
      &:hover,
      &:focus {
        &:before {
          font-size:36px;
        }
        &:after {
          background:rgba(0,0,0,.5);
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #wrapper {
    padding: 56px 0 0 0;
  }
  .main-section {
    .full-width {
      padding:2rem 3rem;
    }
  }
  .cols {
    flex-direction: row;
    .col-half {
      width:50%;
      padding:0;
      .text-block {
        padding:2rem;
      }
    }
  }
}
@media (min-width: 1200px) {

}
@media (min-width: 1380px) {
  .container {
    width:1350px;
    max-width:1350px;
  }
}