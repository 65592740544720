.albums-overview {
  padding:0 2rem 1rem 2rem;
  .album {
    display:block;
    background:#f5f5f5;
    text-decoration: none;
    color:$primary-font-color;
    border-radius:5px;
    margin:0 0 2rem 0;
    cursor:pointer;
    transition:background ease-in-out .3s;
    .album-image {
      display:block;
      overflow:hidden;
      position:relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      &:before {
        content:"\f302";
        font-family:"Font Awesome 5 Free";
        font-weight:700;
        font-size:0;
        color:white;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        transition:font-size ease-in-out .3s;
        z-index:2;
      }
      &:after {
        content:"";
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        background:rgba(0,0,0,0);
        transition:background ease-in-out .3s;
        z-index:1;
      }
    }
    .album-desc {
      display:block;
      padding:2rem;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color:$primary-font-color;
      background:darken(#f5f5f5, 5%);
      .album-image {
        &:before {
          font-size:36px;
        }
        &:after {
          background:rgba(0,0,0,.5);
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .albums-overview {
    padding:0 3rem 1rem 3rem;
  }
}