#home-mededelingen {
  ul {
    margin:0;
    padding:0;
    list-style: none;
    li {
      position:relative;
      padding-left:16px;
      &:before {
        content:"";
        position:absolute;
        top:11px;
        left:0;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left:6px solid $primary-color;
      }
    }
  }
}
.agenda-title {
  display:flex;
  flex-wrap:nowrap;
  position:relative;
  justify-content: space-between;
  align-items: center;
  h2 {
    float:left;
  }
  a {
    float:right;
    margin-bottom:1.4rem;
    &:hover,
    &:focus {
      color:$primary-color;
    }
  }
}
.latest-events {
  position:relative;
  width:100%;
  .text-block {
    h2 {
      position:relative;
      padding:0 0 1rem 0;
      margin:0 0 1rem 0;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width:50px;
        height:3px;
        background:$primary-color;
      }
    }
  }
  .event-list {
    margin:0;
    padding:0;
    list-style:none;
    li {
      a {
        display:flex;
        flex-wrap:wrap;
        flex-direction:row;
        position:relative;
        margin:0 0 .5rem 0;
        background:#f5f5f5;
        .date {
          width:65px;
          height:65px;
          background:$primary-color;
          color:white;
          padding:0;
          text-align:center;
          display:flex;
          flex-wrap:wrap;
          flex-direction: column;
          flex:0 60px;
          justify-content: center;
          align-items: center;
          .day {
            font-size:28px;
            font-weight:700;
            line-height:1;
          }
          .month {
            font-size:18px;
            line-height:1;
            font-weight:300;
            text-transform:uppercase;
          }
        }
        .eventItem {
          padding:0 1rem;
          display:flex;
          flex-wrap:wrap;
          justify-content: flex-start;
          align-content:center;
          flex:1;

          h3 {
            @include font_H3();
            display:block;
            width:100%;
            margin:0 0 .25rem 0;
            padding:0;
            line-height:1.1;
          }
          .time {
            line-height:1.1;
            color:$primary-font-color;
          }
        }
        &:hover,
        &:focus {
          background:darken(#f5f5f5, 8%);
          text-decoration: none;
          .eventItem {
            .time {
              color:$secondary-color;
            }
          }
        }
      }


    }
  }
}