@mixin font_P1 {
  font-family: $primary-font-family;
  font-size:16px;
  font-weight:400;
  line-height:30px;
  position:relative;
  @media (min-width: 992px) {
    font-size:16px;
    line-height:30px;
  }
  @media (min-width: 1200px) {
    font-size:16px;
    line-height:30px;
  }
}

@mixin font_H1 {
  font-family: $primary-font-family;
  font-size:32px;
  font-weight:700;
  line-height:40px;
  position:relative;
  @media (min-width: 992px) {
    font-size:36px;
    line-height:44px;
  }
  @media (min-width: 1200px) {
    font-size:40px;
    line-height:50px;
  }
}

@mixin font_H2 {
  font-family: $primary-font-family;
  font-size:24px;
  font-weight:700;
  line-height:30px;
  position:relative;
  @media (min-width: 992px) {
    font-size:22px;
    line-height:26px;
  }
  @media (min-width: 1200px) {
    font-size:26px;
    line-height:30px;
  }
  @media (min-width: 1380px) {
    font-size:30px;
    line-height:38px;
  }
}

@mixin font_H3 {
  font-family: $primary-font-family;
  font-size:20px;
  font-weight:700;
  line-height:24px;
  position:relative;
  @media (min-width: 992px) {
    font-size:18px;
    line-height:24px;
  }
  @media (min-width: 1200px) {
    font-size:19px;
    line-height:25px;
  }
  @media (min-width: 1380px) {
    font-size:22px;
    line-height:30px;
  }
}