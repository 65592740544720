.btn {
  border-radius:5px;
  padding:.75rem 2rem;
  border:1px solid darken ($primary-color);
  color:$primary-color;
  text-align:center;
  text-transform:uppercase;
  outline:0;
  &:hover,
  &:focus {
    outline:0;
  }
}
.btn.btn-primary,
a.btn.btn-primary{
  background:$primary-color;
  border:1px solid darken($primary-color, 8%);
  border-bottom:3px solid darken($primary-color, 8%);
  color:white;
  box-shadow:0 0;
  &:hover,
  &:focus {
    background:darken($primary-color, 5%);
    border:1px solid darken($primary-color, 12%);
    border-bottom:3px solid darken($primary-color, 12%);
    box-shadow:0 0;
  }
}
.btn.btn-secondary,
a.btn.btn-secondary {
  background:$secondary-color;
  border:1px solid darken($secondary-color, 8%);
  border-bottom:3px solid darken($secondary-color, 8%);
  color:white;
  &:hover,
  &:focus {
    background:darken($secondary-color, 5%);
    border:1px solid darken($secondary-color, 12%);
    border-bottom:3px solid darken($secondary-color, 12%);
  }
}
.btn.btn-readmore {
  position:relative;
  padding-right:3rem;
  &:after {
    content:"\f0a9";
    font-family: "Font Awesome 5 Free";
    font-size:20px;
    font-weight:700;
    position:absolute;
    right:1rem;
    top:50%;
    transform:translateY(-50%);
  }
}

.back {
  display: inline-block;
  position:relative;
  color:$secondary-color;
  text-decoration: none;
  transition:color ease-in-out .3s;
  &:hover,
  &:focus {
    color:$primary-color;
    text-decoration: none;
  }
}
.main-section {
  .back {
    padding:1.5rem 3rem 0 3rem;
  }
}

@media (min-width: 992px) {
  .btn {
    transition:all ease-in-out .3s;
  }
}