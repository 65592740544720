footer {
  background:$secondary-color;
  color:white;
  padding:1rem 0 0 0;
  .bottom {
    background:darken($secondary-color, 10%);
    width:100%;
    text-align:center;
    margin-top:1rem;
    padding:.25rem 0;
    position:relative;
    a {
      color:white;
      font-size:13px;
    }
  }
  .center {
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    .socials {
      list-style:none;
      margin:0 0 0 2rem;
      padding:0;
      display:flex;
      justify-content: center;
      align-items: center;
      position:relative;
      li {
        margin:0 .5rem 0 0;
        a {
          width:36px;
          height:36px;
          position:relative;
          display:block;
          border-radius:50%;
          color:white;
          border:2px solid white;
          i {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
          }
          &:hover,
          &:focus {
            color:$secondary-color;
            background:white;
          }
        }
        &:last-child {
          margin:0;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  footer {
    .center {
      .socials {
        li {
          a {
            transition:all ease-in-out .3s;
          }
        }
      }
    }
  }
}