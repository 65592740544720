.fixed-top {
  position:fixed;
  width:100%;
  top:0;
  left:0;
  right:0;
  margin:0 auto;
  background: $secondary-color;
  justify-content: flex-end;
  padding:1rem;

  button.navbar-toggler {
    background: transparent;
    border: 0;
    float: right;
    width: 44px;
    height: 24px;
    position: relative;
    margin: 0;
    padding:0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      background: white;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 10px;
      width:32px;
    }

    span:nth-child(3) {
      top: 20px;
      width:40px;
    }
  }
  .navbar-toggler.open span:nth-child(1) {
    top: 12px;
    width:32px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .navbar-toggler.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .navbar-toggler.open span:nth-child(3) {
    top: 12px;
    width:32px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .brand {
    top:8px;
    max-width:75px;
    position:absolute;
    left:1rem;
    img {
      max-width:100%;
      height:auto !important;
    }
  }
  .navbar-collapse {
    padding:3rem 0 0 .5rem;
    .navbar-nav {
      li {
        a {
          color:white;
          padding:.5rem 1rem;
          font-size:20px;
          display:block;
          width:100%;
        }
        &.dropdown {
          .dropdown-menu {
            border:0;
            background:darken($secondary-color, 5%);
            color:white;
            li {
              a {
                padding-left:2rem;
                font-size:16px;
              }
            }
          }
        }
      }
    }
  }
  &.smaller {
    .brand {
      top:8px;
      max-width:75px;
    }
  }
}
@media (min-width: 992px) {
  .fixed-top {
    border:0;
    padding:0;
    justify-content: space-between;
    .brand {
      max-width:130px;
      top:1rem;
      transition:all ease-in-out .4s;
    }
    .navbar-collapse {
      padding:0;
      .navbar-nav {
        li {
          border-left:1px solid lighten($secondary-color, 10%);
          a {
            padding:1rem;
            text-decoration: none;
            color:white;
            display:block;
            font-size:16px;
            width:auto;
            &:hover,
            &:focus {
              text-decoration:none;
            }
          }
          &.dropdown {
            .dropdown-toggle {
              &:after {
                margin-left: .355em;
                vertical-align: 0.155em;
                border-top: .25em solid;
                border-right: .25em solid transparent;
                border-left: .25em solid transparent;
                border-bottom: 0;
              }
            }
            .dropdown-menu {
              min-width:18rem;
              padding:.5rem 0;
              margin:0;
              border:0;
              border-radius:0;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              background:darken($secondary-color, 5%);
              color:white;
              li {
                a {
                  color:white;
                  font-size:16px;
                  padding:.25rem 1rem;
                  display:block;
                  &:hover,
                  &:focus {
                    color:black;
                  }
                }
              }
            }
            &:hover,
            &:focus {
              .dropdown-menu {
                display:block;
              }
            }
          }
        }
      }
    }
    &.smaller {
      .brand {
        top:8px;
        max-width:75px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .fixed-top {
    max-width:1140px;
    .brand {
      max-width:140px;
    }
    .navbar-collapse {
      .navbar-nav {
        li {
          a {
            font-size:17px;
          }
        }
      }
    }
    &.smaller {
      .brand {
        top:.5rem;
        left:.75rem;
        max-width:110px;
      }
    }
  }
}
@media (min-width: 1380px) {
  .fixed-top {
    max-width:1350px;
    .brand {
      max-width:160px;
    }
    &.smaller {
      .brand {
        max-width:110px;
      }
    }
  }
}