@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import "partials/_variables";
@import "partials/_mixins";
@import "partials/_global";
@import "partials/_animations";
@import "partials/_buttons";
@import "partials/_nav";
@import "partials/_footer";

@import "layouts/home/_slider";
@import "layouts/home/_latest_events";

@import "layouts/albums/_photoalbum";

@import "layouts/news/_index";
@import "layouts/events/_index";