header {
  width:100%;
  height:auto;
  display:flex;
  flex-direction: column;
  .swiper-container {
    width:100%;
    height:100%;
    position:relative;
    z-index:2;
    .swiper-slide {
      width:100%;
      height:100%;
      .header-image {
        width:100%;
      }
    }
  }
  .news-titles {
    background:$primary-color;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    align-content: flex-start;
    justify-content: flex-start;
    width:100%;
    padding:2rem;
    position:relative;
    z-index:3;
    h2 {
      @include font_H2();
      color:darken($primary-color, 20%);
      padding:0 0 1rem 0;
      margin:0 1rem .25rem 0rem;
      position:relative;
      display:block;
      width:100%;
      &:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        width:50px;
        height:3px;
        background:darken($primary-color, 20%);
      }
    }
    .btn-title {
      width:100%;
      height:auto;
      display:block;
      font-size:18px;
      padding:1rem 0rem;
      border-bottom:1px solid lighten($primary-color, 5%);
      color:white;
      text-decoration: none;
      cursor:pointer;
      &:last-child {
        border-bottom:0;
      }
      &:hover,
      &:focus {
        color:lighten($primary-color, 35%);
      }
    }
    &.hidden-mob {
      display:none;
    }
  }
}

@media (min-width: 992px) {
  header {
    overflow:hidden;
    width:100%;
    height:360px;
    flex-direction: row;
    .swiper-container {
      width:66.666667%;
    }
    .news-items {
      overflow:hidden;
      position:relative;
      max-height:272px;
    }
    .news-titles {
      width:33.333333%;
      height:360px;
      overflow:hidden;
      .btn-title {
        height:90px;
        padding:.5rem 0;
        font-size:16px;
        transition:all ease-in-out .3s;
        i {
          margin-left:3px;
        }
      }
      &:after {
        left:-30px;
      }
      &.hidden-mob {
        display:flex;
      }
    }
  }
}
@media (min-width: 1200px) {
  header {
    height:427px;
    .swiper-container {
      width:66.666667%;
    }
    .news-items {
      max-height:100%;
      position:relative;
      z-index:10;
    }
    .news-titles {
      width:33.333333%;
      height:100%;
      padding:1rem;
      overflow:initial;
      h2 {
        margin-left:0;
        position:relative;
        z-index:10;
      }
      .btn-title {
        height:auto;
        padding:.5rem 0;
      }
      &:after {
        content:"";
        position:absolute;
        width:40px;
        height:125%;
        background:$primary-color;
        top:0;
        left:-20px;
        transform:rotate(3deg);
        z-index:0;
      }
    }
  }
}
@media (min-width: 1380px) {
  header {
    height:506px;
    .swiper-container {
      width:66.666667%;
    }
    .news-titles {
      width:33.333333%;
      padding:2rem;
      .btn-title {
        padding:.75rem 0;
        font-size:18px;
      }
      &:after {
        left:-20px;
      }
    }
  }
}